// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-index-js": () => import("/Users/david/Documents/projects/Portfolio2019/src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-project-index-js": () => import("/Users/david/Documents/projects/Portfolio2019/src/templates/project/index.js" /* webpackChunkName: "component---src-templates-project-index-js" */),
  "component---src-pages-404-js": () => import("/Users/david/Documents/projects/Portfolio2019/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/david/Documents/projects/Portfolio2019/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/david/Documents/projects/Portfolio2019/.cache/data.json")

